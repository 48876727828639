export const COUNTRIES = [
  {
    title: "United States",
    value: "US",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "United Kingdom",
    value: "GB",
    currency: "GBP",
    symbol: "£",
  },
  {
    title: "Australia",
    value: "AU",
    currency: "AUD",
    symbol: "$",
  },
  {
    title: "Singapore",
    value: "SG",
    currency: "SGD",
    symbol: "$",
  },
  {
    title: "India",
    value: "IN",
    currency: "INR",
    symbol: "₹",
  },
  {
    title: "China",
    value: "CN",
    currency: "CNY",
    symbol: "¥",
  },
  {
    title: "Germany",
    value: "DE",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "France",
    value: "FR",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Afghanistan",
    value: "AF",
    currency: "AFN",
    symbol: "؋",
  },
  {
    title: "Albania",
    value: "AL",
    currency: "ALL",
    symbol: "L",
  },
  {
    title: "Algeria",
    value: "DZ",
    currency: "DZD",
    symbol: "د.ج",
  },
  {
    title: "American Samoa",
    value: "AS",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Andorra",
    value: "AD",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Angola",
    value: "AO",
    currency: "AOA",
    symbol: "Kz",
  },
  {
    title: "Anguilla",
    value: "AI",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Argentina",
    value: "AR",
    currency: "ARS",
    symbol: "$",
  },
  {
    title: "Armenia",
    value: "AM",
    currency: "AMD",
    symbol: "֏",
  },
  {
    title: "Aruba",
    value: "AW",
    currency: "AWG",
    symbol: "ƒ",
  },
  {
    title: "Austria",
    value: "AT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
    currency: "AZN",
    symbol: "₼",
  },
  {
    title: "Bahamas",
    value: "BS",
    currency: "BSD",
    symbol: "$",
  },
  {
    title: "Bahrain",
    value: "BH",
    currency: "BHD",
    symbol: "ب.د",
  },
  {
    title: "Bangladesh",
    value: "BD",
    currency: "BDT",
    symbol: "৳",
  },
  {
    title: "Barbados",
    value: "BB",
    currency: "BBD",
    symbol: "$",
  },
  {
    title: "Belarus",
    value: "BY",
    currency: "BYN",
    symbol: "Br",
  },
  {
    title: "Belgium",
    value: "BE",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Belize",
    value: "BZ",
    currency: "BZD",
    symbol: "BZ$",
  },
  {
    title: "Benin",
    value: "BJ",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Bermuda",
    value: "BM",
    currency: "BMD",
    symbol: "$",
  },
  {
    title: "Bhutan",
    value: "BT",
    currency: "BTN",
    symbol: "Nu.",
  },
  {
    title: "Bolivia",
    value: "BO",
    currency: "BOB",
    symbol: "Bs.",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
    currency: "BAM",
    symbol: "KM",
  },
  {
    title: "Botswana",
    value: "BW",
    currency: "BWP",
    symbol: "P",
  },
  {
    title: "Brazil",
    value: "BR",
    currency: "BRL",
    symbol: "R$",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Brunei",
    value: "BN",
    currency: "BND",
    symbol: "$",
  },
  {
    title: "Bulgaria",
    value: "BG",
    currency: "BGN",
    symbol: "лв",
  },
  {
    title: "Burkina Faso",
    value: "BF",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Burundi",
    value: "BI",
    currency: "BIF",
    symbol: "Fr",
  },
  {
    title: "Cambodia",
    value: "KH",
    currency: "KHR",
    symbol: "៛",
  },
  {
    title: "Cameroon",
    value: "CM",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Canada",
    value: "CA",
    currency: "CAD",
    symbol: "$",
  },
  {
    title: "Cape Verde",
    value: "CV",
    currency: "CVE",
    symbol: "$",
  },
  {
    title: "Cayman Islands",
    value: "KY",
    currency: "KYD",
    symbol: "$",
  },
  {
    title: "Central African Republic",
    value: "CF",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Chad",
    value: "TD",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Chile",
    value: "CL",
    currency: "CLP",
    symbol: "$",
  },
  {
    title: "Colombia",
    value: "CO",
    currency: "COP",
    symbol: "$",
  },
  {
    title: "Comoros",
    value: "KM",
    currency: "KMF",
    symbol: "Fr",
  },
  {
    title: "Congo - Brazzaville",
    value: "CG",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Congo - Kinshasa",
    value: "CD",
    currency: "CDF",
    symbol: "Fr",
  },
  {
    title: "Cook Islands",
    value: "CK",
    currency: "NZD",
    symbol: "$",
  },
  {
    title: "Costa Rica",
    value: "CR",
    currency: "CRC",
    symbol: "₡",
  },
  {
    title: "Croatia",
    value: "HR",
    currency: "HRK",
    symbol: "kn",
  },
  {
    title: "Cuba",
    value: "CU",
    currency: "CUP",
    symbol: "$",
  },
  {
    title: "Cyprus",
    value: "CY",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Czech Republic",
    value: "CZ",
    currency: "CZK",
    symbol: "Kč",
  },
  {
    title: "Côte d’Ivoire",
    value: "CI",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Denmark",
    value: "DK",
    currency: "DKK",
    symbol: "kr",
  },
  {
    title: "Djibouti",
    value: "DJ",
    currency: "DJF",
    symbol: "Fr",
  },
  {
    title: "Dominica",
    value: "DM",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Dominican Republic",
    value: "DO",
    currency: "DOP",
    symbol: "$",
  },
  {
    title: "Ecuador",
    value: "EC",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Egypt",
    value: "EG",
    currency: "EGP",
    symbol: "E£",
  },
  {
    title: "El Salvador",
    value: "SV",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Equatorial Guinea",
    value: "GQ",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Eritrea",
    value: "ER",
    currency: "ERN",
    symbol: "Nfk",
  },
  {
    title: "Estonia",
    value: "EE",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Ethiopia",
    value: "ET",
    currency: "ETB",
    symbol: "Br",
  },
  {
    title: "Falkland Islands",
    value: "FK",
    currency: "FKP",
    symbol: "£",
  },
  {
    title: "Faroe Islands",
    value: "FO",
    currency: "DKK",
    symbol: "kr",
  },
  {
    title: "Fiji",
    value: "FJ",
    currency: "FJD",
    symbol: "$",
  },
  {
    title: "Finland",
    value: "FI",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "French Guiana",
    value: "GF",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "French Polynesia",
    value: "PF",
    currency: "XPF",
    symbol: "Fr",
  },
  {
    title: "Gabon",
    value: "GA",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    title: "Gambia",
    value: "GM",
    currency: "GMD",
    symbol: "D",
  },
  {
    title: "Georgia",
    value: "GE",
    currency: "GEL",
    symbol: "₾",
  },
  {
    title: "Ghana",
    value: "GH",
    currency: "GHS",
    symbol: "₵",
  },
  {
    title: "Gibraltar",
    value: "GI",
    currency: "GIP",
    symbol: "£",
  },
  {
    title: "Greece",
    value: "GR",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Greenland",
    value: "GL",
    currency: "DKK",
    symbol: "kr",
  },
  {
    title: "Grenada",
    value: "GD",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Guadeloupe",
    value: "GP",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Guam",
    value: "GU",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Guatemala",
    value: "GT",
    currency: "GTQ",
    symbol: "Q",
  },
  {
    title: "Guinea",
    value: "GN",
    currency: "GNF",
    symbol: "Fr",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Guyana",
    value: "GY",
    currency: "GYD",
    symbol: "$",
  },
  {
    title: "Haiti",
    value: "HT",
    currency: "HTG",
    symbol: "G",
  },
  {
    title: "Honduras",
    value: "HN",
    currency: "HNL",
    symbol: "L",
  },
  {
    title: "Hong Kong SAR China",
    value: "HK",
    currency: "HKD",
    symbol: "$",
  },
  {
    title: "Hungary",
    value: "HU",
    currency: "HUF",
    symbol: "Ft",
  },
  {
    title: "Iceland",
    value: "IS",
    currency: "ISK",
    symbol: "kr",
  },
  {
    title: "Indonesia",
    value: "ID",
    currency: "IDR",
    symbol: "Rp",
  },
  {
    title: "Iran",
    value: "IR",
    currency: "IRR",
    symbol: "﷼",
  },
  {
    title: "Iraq",
    value: "IQ",
    currency: "IQD",
    symbol: "ع.د",
  },
  {
    title: "Ireland",
    value: "IE",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Israel",
    value: "IL",
    currency: "ILS",
    symbol: "₪",
  },
  {
    title: "Italy",
    value: "IT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Jamaica",
    value: "JM",
    currency: "JMD",
    symbol: "J$",
  },
  {
    title: "Japan",
    value: "JP",
    currency: "JPY",
    symbol: "¥",
  },
  {
    title: "Jordan",
    value: "JO",
    currency: "JOD",
    symbol: "د.ا",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
    currency: "KZT",
    symbol: "₸",
  },
  {
    title: "Kenya",
    value: "KE",
    currency: "KES",
    symbol: "Ksh",
  },
  {
    title: "Kiribati",
    value: "KI",
    currency: "AUD",
    symbol: "$",
  },
  {
    title: "Kuwait",
    value: "KW",
    currency: "KWD",
    symbol: "د.ك",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
    currency: "KGS",
    symbol: "с",
  },
  {
    title: "Laos",
    value: "LA",
    currency: "LAK",
    symbol: "₭",
  },
  {
    title: "Latvia",
    value: "LV",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Lebanon",
    value: "LB",
    currency: "LBP",
    symbol: "ل.ل",
  },
  {
    title: "Lesotho",
    value: "LS",
    currency: "LSL",
    symbol: "L",
  },
  {
    title: "Liberia",
    value: "LR",
    currency: "LRD",
    symbol: "$",
  },
  {
    title: "Libya",
    value: "LY",
    currency: "LYD",
    symbol: "ل.د",
  },
  {
    title: "Liechtenstein",
    value: "LI",
    currency: "CHF",
    symbol: "Fr",
  },
  {
    title: "Lithuania",
    value: "LT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Luxembourg",
    value: "LU",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Macau SAR China",
    value: "MO",
    currency: "MOP",
    symbol: "MOP$",
  },
  {
    title: "Macedonia",
    value: "MK",
    currency: "MKD",
    symbol: "ден",
  },
  {
    title: "Madagascar",
    value: "MG",
    currency: "MGA",
    symbol: "Ar",
  },
  {
    title: "Malawi",
    value: "MW",
    currency: "MWK",
    symbol: "MK",
  },
  {
    title: "Malaysia",
    value: "MY",
    currency: "MYR",
    symbol: "RM",
  },
  {
    title: "Maldives",
    value: "MV",
    currency: "MVR",
    symbol: "ރ",
  },
  {
    title: "Mali",
    value: "ML",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Malta",
    value: "MT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Marshall Islands",
    value: "MH",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Martinique",
    value: "MQ",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Mauritania",
    value: "MR",
    currency: "MRO",
    symbol: "UM",
  },
  {
    title: "Mauritius",
    value: "MU",
    currency: "MUR",
    symbol: "₨",
  },
  {
    title: "Mayotte",
    value: "YT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Mexico",
    value: "MX",
    currency: "MXN",
    symbol: "$",
  },
  {
    title: "Micronesia",
    value: "FM",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Moldova",
    value: "MD",
    currency: "MDL",
    symbol: "L",
  },
  {
    title: "Monaco",
    value: "MC",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Mongolia",
    value: "MN",
    currency: "MNT",
    symbol: "₮",
  },
  {
    title: "Montenegro",
    value: "ME",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Montserrat",
    value: "MS",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Morocco",
    value: "MA",
    currency: "MAD",
    symbol: "د.م.",
  },
  {
    title: "Mozambique",
    value: "MZ",
    currency: "MZN",
    symbol: "MT",
  },
  {
    title: "Myanmar (Burma)",
    value: "MM",
    currency: "MMK",
    symbol: "K",
  },
  {
    title: "Namibia",
    value: "NA",
    currency: "NAD",
    symbol: "$",
  },
  {
    title: "Nauru",
    value: "NR",
    currency: "AUD",
    symbol: "$",
  },
  {
    title: "Nepal",
    value: "NP",
    currency: "NPR",
    symbol: "₨",
  },
  {
    title: "Netherlands",
    value: "NL",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "New Caledonia",
    value: "NC",
    currency: "XPF",
    symbol: "Fr",
  },
  {
    title: "New Zealand",
    value: "NZ",
    currency: "NZD",
    symbol: "$",
  },
  {
    title: "Nicaragua",
    value: "NI",
    currency: "NIO",
    symbol: "C$",
  },
  {
    title: "Niger",
    value: "NE",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Nigeria",
    value: "NG",
    currency: "NGN",
    symbol: "₦",
  },
  {
    title: "Niue",
    value: "NU",
    currency: "NZD",
    symbol: "$",
  },
  {
    title: "Norfolk Island",
    value: "NF",
    currency: "AUD",
    symbol: "$",
  },
  {
    title: "North Korea",
    value: "KP",
    currency: "KPW",
    symbol: "₩",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Norway",
    value: "NO",
    currency: "NOK",
    symbol: "kr",
  },
  {
    title: "Oman",
    value: "OM",
    currency: "OMR",
    symbol: "ر.ع.",
  },
  {
    title: "Pakistan",
    value: "PK",
    currency: "PKR",
    symbol: "₨",
  },
  {
    title: "Palau",
    value: "PW",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Palestinian Territories",
    value: "PS",
    currency: "ILS",
    symbol: "₪",
  },
  {
    title: "Panama",
    value: "PA",
    currency: "PAB",
    symbol: "B/.",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
    currency: "PGK",
    symbol: "K",
  },
  {
    title: "Paraguay",
    value: "PY",
    currency: "PYG",
    symbol: "₲",
  },
  {
    title: "Peru",
    value: "PE",
    currency: "PEN",
    symbol: "S/.",
  },
  {
    title: "Philippines",
    value: "PH",
    currency: "PHP",
    symbol: "₱",
  },
  {
    title: "Pitcairn Islands",
    value: "PN",
    currency: "NZD",
    symbol: "$",
  },
  {
    title: "Poland",
    value: "PL",
    currency: "PLN",
    symbol: "zł",
  },
  {
    title: "Portugal",
    value: "PT",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Puerto Rico",
    value: "PR",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Qatar",
    value: "QA",
    currency: "QAR",
    symbol: "ر.ق",
  },
  {
    title: "Romania",
    value: "RO",
    currency: "RON",
    symbol: "lei",
  },
  {
    title: "Russia",
    value: "RU",
    currency: "RUB",
    symbol: "₽",
  },
  {
    title: "Rwanda",
    value: "RW",
    currency: "RWF",
    symbol: "Fr",
  },
  {
    title: "Réunion",
    value: "RE",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Saint Barthélemy",
    value: "BL",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Saint Helena",
    value: "SH",
    currency: "SHP",
    symbol: "£",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Saint Lucia",
    value: "LC",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Saint Martin",
    value: "MF",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
    currency: "XCD",
    symbol: "$",
  },
  {
    title: "Samoa",
    value: "WS",
    currency: "WST",
    symbol: "T",
  },
  {
    title: "San Marino",
    value: "SM",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
    currency: "SAR",
    symbol: "ر.س",
  },
  {
    title: "Senegal",
    value: "SN",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Serbia",
    value: "RS",
    currency: "RSD",
    symbol: "din.",
  },
  {
    title: "Seychelles",
    value: "SC",
    currency: "SCR",
    symbol: "₨",
  },
  {
    title: "Sierra Leone",
    value: "SL",
    currency: "SLL",
    symbol: "Le",
  },
  {
    title: "Sint Maarten",
    value: "SX",
    currency: "ANG",
    symbol: "ƒ",
  },
  {
    title: "Slovakia",
    value: "SK",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Slovenia",
    value: "SI",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Solomon Islands",
    value: "SB",
    currency: "SBD",
    symbol: "SI$",
  },
  {
    title: "Somalia",
    value: "SO",
    currency: "SOS",
    symbol: "S",
  },
  {
    title: "South Africa",
    value: "ZA",
    currency: "ZAR",
    symbol: "R",
  },
  {
    title: "South Georgia & South Sandwich Islands",
    value: "GS",
    currency: "GBP",
    symbol: "£",
  },
  {
    title: "South Korea",
    value: "KR",
    currency: "KRW",
    symbol: "₩",
  },
  {
    title: "South Sudan",
    value: "SS",
    currency: "SSP",
    symbol: "£",
  },
  {
    title: "Spain",
    value: "ES",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Sri Lanka",
    value: "LK",
    currency: "LKR",
    symbol: "₨",
  },
  {
    title: "Sudan",
    value: "SD",
    currency: "SDG",
    symbol: "ج.س.",
  },
  {
    title: "Suriname",
    value: "SR",
    currency: "SRD",
    symbol: "$",
  },
  {
    title: "Svalbard and Jan Mayen",
    value: "SJ",
    currency: "NOK",
    symbol: "kr",
  },
  {
    title: "Swaziland",
    value: "SZ",
    currency: "SZL",
    symbol: "E",
  },
  {
    title: "Sweden",
    value: "SE",
    currency: "SEK",
    symbol: "kr",
  },
  {
    title: "Switzerland",
    value: "CH",
    currency: "CHF",
    symbol: "Fr",
  },
  {
    title: "Syria",
    value: "SY",
    currency: "SYP",
    symbol: "£",
  },
  {
    title: "São Tomé and Príncipe",
    value: "ST",
    currency: "STN",
    symbol: "Db",
  },
  {
    title: "Taiwan",
    value: "TW",
    currency: "TWD",
    symbol: "NT$",
  },
  {
    title: "Tajikistan",
    value: "TJ",
    currency: "TJS",
    symbol: "ЅМ",
  },
  {
    title: "Tanzania",
    value: "TZ",
    currency: "TZS",
    symbol: "TSh",
  },
  {
    title: "Thailand",
    value: "TH",
    currency: "THB",
    symbol: "฿",
  },
  {
    title: "Timor-Leste",
    value: "TL",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Togo",
    value: "TG",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    title: "Tokelau",
    value: "TK",
    currency: "NZD",
    symbol: "$",
  },
  {
    title: "Tonga",
    value: "TO",
    currency: "TOP",
    symbol: "T$",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
    currency: "TTD",
    symbol: "TT$",
  },
  {
    title: "Tunisia",
    value: "TN",
    currency: "TND",
    symbol: "د.ت",
  },
  {
    title: "Turkey",
    value: "TR",
    currency: "TRY",
    symbol: "₺",
  },
  {
    title: "Turkmenistan",
    value: "TM",
    currency: "TMT",
    symbol: "m",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Tuvalu",
    value: "TV",
    currency: "AUD",
    symbol: "$",
  },
  {
    title: "U.S. Outlying Islands",
    value: "UM",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
    currency: "USD",
    symbol: "$",
  },
  {
    title: "Uganda",
    value: "UG",
    currency: "UGX",
    symbol: "USh",
  },
  {
    title: "Ukraine",
    value: "UA",
    currency: "UAH",
    symbol: "₴",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
    currency: "AED",
    symbol: "د.إ",
  },
  {
    title: "Uruguay",
    value: "UY",
    currency: "UYU",
    symbol: "$",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
    currency: "UZS",
    symbol: "лв",
  },
  {
    title: "Vanuatu",
    value: "VU",
    currency: "VUV",
    symbol: "VT",
  },
  {
    title: "Vatican City",
    value: "VA",
    currency: "EUR",
    symbol: "€",
  },
  {
    title: "Venezuela",
    value: "VE",
    currency: "VES",
    symbol: "Bs.",
  },
  {
    title: "Vietnam",
    value: "VN",
    currency: "VND",
    symbol: "₫",
  },
  {
    title: "Wallis and Futuna",
    value: "WF",
    currency: "XPF",
    symbol: "Fr",
  },
  {
    title: "Western Sahara",
    value: "EH",
    currency: "MAD",
    symbol: "د.م.",
  },
  {
    title: "Yemen",
    value: "YE",
    currency: "YER",
    symbol: "﷼",
  },
  {
    title: "Zambia",
    value: "ZM",
    currency: "ZMW",
    symbol: "ZK",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
    currency: "ZWL",
    symbol: "$",
  },
];
